import React from "react"
import { Link } from "gatsby"

class Hero extends React.Component {
	render() {
		return (
			<div className="hero-section bg-light">
				<div className="swiper-slide hero-bg-2">
					<div className="row justify-content-center">
						<div className="col-md-9">
							<h1 className="display-4 font-weight-bold flair mb-5">
								Neighborhood IT is the answer to your IT headaches.
							</h1>
							<Link to="/#contact-us" className="hero-cta">
								Schedule a Consultation
							</Link>
						</div>
					</div>
				</div>
				{/*<MySwiper slidesperview={1}>
					<div className="swiper-slide hero-bg-1">
						<div className="col-md-12">
							<h1 className="display-4 font-weight-bold flair">
								Data Protection
							</h1>
							<p className="lead">
								Protect your hard work with simple, reliable and cost-effective
								data protection services.
							</p>
							<Link to="/data-protection" className="hero-cta">
								READ MORE
							</Link>
						</div>
					</div>
					<div className="swiper-slide hero-bg-2">
						<div className="col-md-12">
							<h1 className="display-4 font-weight-bold flair">
								Hourly IT Support
							</h1>
							<p className="lead">
								Keep your business running smoothly with timely assistance from
								our friendly IT experts.
							</p>
							<Link to="/hourly-it" className="hero-cta">
								READ MORE
							</Link>
						</div>
					</div>
					<div className="swiper-slide hero-bg-3">
						<div className="col-md-12">
							<h1 className="display-4 font-weight-bold flair">
								Managed Services
							</h1>
							<p className="lead">
								Cut distractions, headaches and unpredictable IT costs with our
								popular managed services.
							</p>
							<Link to="/managed-services" className="hero-cta">
								READ MORE
							</Link>
						</div>
					</div>
				</MySwiper>*/}
			</div>
		)
	}
}

export default Hero
