import React from "react"
import { Link, graphql } from "gatsby"
// import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import WebriQForm from "@components/form/form"

import Layout from "@components/layout"
import SEO from "@components/seo"
// import { Container } from "reactstrap"
// import MySwiper from "../components/swiper/swiper"
// import SampleForm from "../components/form/form"
import Hero from "@components/hero"
import "@components/layout.css"

import DATA from "@images/data.png"
import HOURLY from "@images/hourly.png"
import MANAGE from "@images/manage.png"
import NOPROFIT from "@images/nopayment.png"
import READMOREGREEN from "@images/readmore-btn.png"
import READMOREBLACK from "@images/readmore-btn-black.png"
// import BLOG from "@images/blog.jpg"
// import BLOG2 from "@images/blog2.jpg"
// import BLOG3 from "@images/blog3.jpg"

class IndexPage extends React.Component {
	render() {
		const siteTitle = this.props.data.site.siteMetadata.title
		const siteDescription = this.props.data.site.siteMetadata.description
		const siteKeywords = this.props.data.site.siteMetadata.keywords
		// const posts = this.props.data.allSanityPost.edges
		// console.log(posts)
		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO
					title={siteTitle}
					description={siteDescription}
					keywords={siteKeywords}
				/>
				<Hero />
				<section id="services">
					<div className="service-section">
						<div className="row">
							<div className="col-lg-3 col-md-6 p-0">
								<div className="service-list data text-center text-white h-100">
									<div className="height-wrapper">
										<div className="service-img mb-4">
											<img
												className="data img-fluid"
												src={DATA}
												alt="Data Protection"
											/>
										</div>
										<h3 className="service-title">Data Protection</h3>
										<h5 className="service-desc">
											Protect your hard work with simple, reliable and
											cost-effective data protection services.
										</h5>
									</div>
									<Link className="service-url" to="/data-protection">
										<img
											className="img-fluid"
											src={READMOREGREEN}
											alt="Read More"
										/>
									</Link>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 p-0">
								<div className="service-list hourly text-center text-white h-100">
									<div className="height-wrapper">
										<div className="service-img mb-4">
											<img
												className="hourly img-fluid"
												src={HOURLY}
												alt="Hourly It Support"
											/>
										</div>
										<h3 className="service-title">Hourly IT Support</h3>
										<h5 className="service-desc">
											Keep your business running smoothly with timely assistance
											from our friendly IT experts.
										</h5>
									</div>
									<Link className="service-url" to="/hourly-it">
										<img
											className="img-fluid"
											src={READMOREBLACK}
											alt="Read More"
										/>
									</Link>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 p-0">
								<div className="service-list data text-center text-white h-100">
									<div className="height-wrapper">
										<div className="service-img mb-4">
											<img
												className="data img-fluid"
												src={MANAGE}
												alt="Manage Services"
											/>
										</div>
										<h3 className="service-title">Managed Services</h3>
										<h5 className="service-desc">
											Cut distractions, headaches and unpredictable IT costs
											with our popular managed services.
										</h5>
									</div>
									<Link className="service-url" to="/managed-services">
										<img
											className="img-fluid"
											src={READMOREGREEN}
											alt="Read More"
										/>
									</Link>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 p-0">
								<div className="service-list hourly text-center text-white h-100">
									<div className="height-wrapper">
										<div className="service-img mb-4">
											<img
												className="hourly img-fluid"
												src={NOPROFIT}
												alt="Not for Profit Home Services"
											/>
										</div>
										<h3 className="service-title">
											Not-for-Profit Home Services
										</h3>
										<h5 className="service-desc">
											Residential services provided to members of our community
											on a not-for-profit basis.
										</h5>
									</div>
									<Link className="service-url" to="/not-for-profit">
										<img
											className="img-fluid"
											src={READMOREBLACK}
											alt="Read More"
										/>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="about-us">
					<div id="about" className="about-section">
						<div className="container">
							<div className="row">
								<div className="col-md-5">
									<div className="about-content-left"></div>
								</div>
								<div className="col-md-7">
									<div className="about-content-right text-left">
										<h2 className="section-title text-black">
											About
											<br />
											Neighborhood{" "}
											<span
												className="text-green"
												style={{ fontWeight: `900` }}
											>
												IT
											</span>
										</h2>
										<h4 className="section-subtitle">
											We offer enterprise-class managed IT services to small
											businesses in order to level the competitive playing
											field.
										</h4>
										<p className="section-p p-dark">
											All products and solutions we recommend have zero mark-ups
											from our end. They are also at wholesale prices, which
											translates to more cost-effective IT support. We only ever
											charge for our consulting services.
										</p>
										<p className="section-p p-dark">
											This solution-agnostic approach frees us up to recommend
											low-cost cloud and open-source solutions that are often
											passed over by more traditional IT firms due to their
											inability to add a tremendous mark-up.
										</p>
										<p className="section-p p-dark">
											Experience the pure consulting difference today. Call us
											for a free onsite consultation.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/*<section id="our-blog">
					<div id="blog" className="blog-section">
						<div className="container">
							<div className="row">
								<div className="col">
									<div className="blog-body text-center">
										<h2 className="section-title text-white">
											Our <span style={{ color: "#6fbc1f" }}>Blog</span>
										</h2>
										<p className="section-p text-white">
											Lorem Ipsum has been the industry's standard dummy text
											ever since the 1500s, when an unknown printer took a
											galley of type and scrambled it to make a type specimen
											book.
										</p>
									</div>
									<div className="row">
										<div className="col-md-4">
											<div
												data-aos="fade-left"
												data-aos-duration={1200}
												className="blog-content"
											>
												<div className="blog-image">
													<a href="/">
														<img
															className="img-fluid mb-0"
															src={BLOG}
															alt="Blog 1"
														/>
													</a>
												</div>
												<div className="blog-info text-center">
													<a href="/">
														<h3 className="blog-title1 text-dark">Blog 1</h3>
													</a>
													<p className="blog-p">
														It is a long established fact that a reader will be
														distracted by the readable content of a page when
														looking at its layout. The point of using Lorem
														Ipsum is that it has a more-or-less normal
														distribution of letters, as opposed to using
														'Content here, content here', making it look like
														readable English.
													</p>
													<a className="blog-link mb-4" href="/">
														Read More
													</a>
													<div className="blog-date-author d-flex align-content-center justify-content-between">
														<p className="blog-date">January 1, 2020</p>
														<p className="blog-author">Admin</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div
												data-aos="fade-left"
												data-aos-duration={1600}
												className="blog-content"
											>
												<div className="blog-image">
													<a href="/">
														<img
															className="img-fluid mb-0"
															src={BLOG2}
															alt="Blog 2"
														/>
													</a>
												</div>
												<div className="blog-info text-center">
													<a href="/">
														<h3 className="blog-title1 text-dark">Blog 2</h3>
													</a>
													<p className="blog-p">
														It is a long established fact that a reader will be
														distracted by the readable content of a page when
														looking at its layout. The point of using Lorem
														Ipsum is that it has a more-or-less normal
														distribution of letters, as opposed to using
														'Content here, content here', making it look like
														readable English.
													</p>
													<a className="blog-link mb-4" href="/">
														Read More
													</a>
													<div className="blog-date-author d-flex align-content-center justify-content-between">
														<p className="blog-date">January 1, 2020</p>
														<p className="blog-author">Admin</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div
												data-aos="fade-left"
												data-aos-duration={1900}
												className="blog-content"
											>
												<div className="blog-image">
													<a href="/">
														<img
															className="img-fluid mb-0"
															src={BLOG3}
															alt="Blog 3"
														/>
													</a>
												</div>
												<div className="blog-info text-center">
													<a href="/">
														<h3 className="blog-title1 text-dark">Blog 3</h3>
													</a>
													<p className="blog-p">
														It is a long established fact that a reader will be
														distracted by the readable content of a page when
														looking at its layout. The point of using Lorem
														Ipsum is that it has a more-or-less normal
														distribution of letters, as opposed to using
														'Content here, content here', making it look like
														readable English.
													</p>
													<a className="blog-link mb-4" href="/">
														Read More
													</a>
													<div className="blog-date-author d-flex align-content-center justify-content-between">
														<p className="blog-date">January 1, 2020</p>
														<p className="blog-author">Admin</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>*/}

				<section id="join-our-newsletter">
					<div id="newsletter" className="newsletter-section">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-md-6">
									<div className="newsletter-content">
										<h3 className="section-title mb-0 text-white">
											Join Our Newsletter Now
										</h3>
									</div>
								</div>
								<div className="col-md-6">
									<div className="newsletter-link text-right">
										<Link className="newsletter-url text-center" to="/#contact">
											Get the latest technology news here
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="contact-us">
					<div id="contact" className="contact-section">
						<div className="container">
							<div className="row justify-content-md-center justify-content-lg-end">
								<div className="col-md-9 col-lg-5">
									<div className="consultation-content">
										<h2 className="section-title text-dark">
											Get a <span style={{ color: "#6fbc1f" }}>Free</span>
											<br /> Consultation!
										</h2>
										<p className="section-p">
											Fill out the form below to receive a free consultation and
											learn how we can make your technology worry-free!
										</p>
										<WebriQForm
											data-form-id="5e707ae515573936df00bc69"
											name="Contact Form"
											className="contact-form"
										>
											<div className="form-group">
												<input
													type="text"
													className="form-control"
													id="firstName"
													name="First Name"
												/>
												<label htmlFor="firstName">First Name</label>
											</div>
											<div className="form-group">
												<input
													type="text"
													className="form-control"
													id="lastName"
													name="Last Name"
												/>
												<label htmlFor="lastName">Last Name</label>
											</div>
											<div className="form-group">
												<input
													type="email"
													className="form-control"
													id="emailAddress"
													name="Email Address"
												/>
												<label htmlFor="emailAddress">Email Address</label>
											</div>
											<div className="form-group">
												<input
													type="text"
													className="form-control"
													id="phoneNumber"
													required
													name="Phone Number"
												/>
												<label htmlFor="phoneNumber">Phone Number</label>
											</div>
											<div className="form-group">
												<input
													type="text"
													className="form-control"
													id="company"
													name="Company"
												/>
												<label htmlFor="company">Company</label>
											</div>
											<div className="form-group">
												<div className="webriq-recaptcha" />
											</div>
											<button
												className="btn btn-primary form-cta"
												type="submit"
											>
												Submit Now
											</button>
										</WebriQForm>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="managed-services">
					<div id="managed" className="managed-section">
						<div className="container">
							<div className="row">
								<div className="col-md-5">
									<div className="managed-content-left"></div>
								</div>
								<div className="col-md-7">
									<div className="managed-content-right text-left">
										<h2 className="section-title text-black">
											Managed{" "}
											<span
												className="text-green"
												style={{ fontWeight: `900` }}
											>
												IT
											</span>{" "}
											Services and Support for Small to Medium Businesses in
											Colorado
										</h2>
										<p className="section-p p-dark">
											Neighborhood IT provides managed IT solutions to
											businesses in Colorado. If you have information
											technology-related issues, we are here to take away the
											stress.
										</p>
										<p className="section-p p-dark">
											We are not your traditional managed service provider, but
											rather a Pure Consultancy. This means that we do not
											resell, passthrough, or markup the products and services
											we recommend to our clients. This vendor-agnostic approach
											ensures that our IT support for businesses only offers
											recommended solutions that are truly in the best interests
											of the client, not what provides the best margin to us.
										</p>
										<p className="section-p p-dark">
											We leverage vendor relationships to provide
											enterprise-grade I.T. solutions at a price point not
											otherwise available to the small business market and
											deliver them in a way that removes the burden of IT from
											the business owners.
										</p>
										<p className="section-p p-dark">
											The services we offer include:
										</p>
										<ul>
											<li>desktop, server, and network management</li>
											<li>cloud/SaaS</li>
											<li>IT security and training</li>
											<li>Virtual CIO services</li>
										</ul>
										<h2>
											Why You Can Trust Neighborhood{" "}
											<span
												className="text-green"
												style={{ fontWeight: `900` }}
											>
												IT
											</span>
											.
										</h2>
										<p className="section-p p-dark">
											At the helm of our consultancy is Josh Downing. He is a
											20-year industry veteran who grew up in the financial
											sector of IT, learning proper procedure, documentation,
											process, and change management early on.
										</p>
										<p className="section-p p-dark">
											We maintain contracts with other IT consultants &
											consultancies in the Front Range area. We provide vacation
											coverage and large-project assistance for each other and
											leverage each of our unique deep skillsets when a client’s
											needs require them. This gives us the low overhead and
											agility of a small company with the capabilities of a much
											larger one.
										</p>
										<h2>How We Can Help You</h2>
										<p className="section-p p-dark">
											We are an IT Consultancy in Colorado that will assist you
											when it comes to monitoring, patching, backups, and
											security for workstations, servers, and cloud services.
											Our managed IT consulting services are reliable and will
											be tailor-made to your needs.
										</p>
										<p>
											You can take advantage of our security awareness training
											as well. We can help small to medium-sized businesses in
											educating their staff on identifying and avoiding
											phishing, vishing (voice phishing/calls), and malware.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</Layout>
		)
	}
}

export default IndexPage

export const indexPageQuery = graphql`
	query indexPageQuery {
		site {
			siteMetadata {
				title
				author
				description
				keywords
			}
		}
	}
`
